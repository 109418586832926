
import {
  tagIdx,
  adminDataTable,
  selectLangIdx,
} from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";
export default {
  setup() {
    const { editDescription } = useRequestAdminTable();
    // console.log(adminDataTable.value[tagIdx.value]);

    return { tagIdx, adminDataTable, editDescription, selectLangIdx };
  },
};
