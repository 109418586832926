<template>
  <div
    class="modal fade"
    id="modifyhealthcaremodal"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            編輯項目：{{ adminDataTable[tagIdx].type }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h6>衛教建議：</h6>
          <div class="mb-3">
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="selectLangIdx"
            >
              <option selected disabled>選擇語言</option>
              <option
                v-for="(item, i) in adminDataTable[tagIdx].suggestions[
                  suggestionDataIdx
                ].text_contents"
                :key="i"
                :value="i"
              >
                {{ item.lang }}
              </option>
            </select>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="
                adminDataTable[tagIdx].suggestions[suggestionDataIdx]
                  .text_contents[selectLangIdx].text_content
              "
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            取消
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="
              editSuggestion(
                adminDataTable[tagIdx],
                adminDataTable[tagIdx].suggestions[suggestionDataIdx],
                selectLangIdx
              )
            "
          >
            修改
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  tagIdx,
  adminDataTable,
  suggestionDataIdx,
  selectLangIdx
} from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";

export default {
  setup() {
    const { editSuggestion } = useRequestAdminTable();
    return {
      tagIdx,
      adminDataTable,
      editSuggestion,
      suggestionDataIdx,
      selectLangIdx
    };
  }
};
</script>
