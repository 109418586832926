
import { onMounted, defineComponent, ref } from "vue";
import AddEcgJudgeModal from "../../components/admin/modal/AddEcgJudgeModal.vue";
import AddLanguageModal from "../../components/admin/modal/AddLanguageModal.vue";
import ModifyCommentNameModal from "../../components/admin/modal/ModifyCommentNameModal.vue";
import ModifyReportModal from "../../components/admin/modal/ModifyReportModal.vue";
import HealthCareListModal from "../../components/admin/modal/HealthCareListModal.vue";
import DeleteAiCommentModal from "../../components/admin/modal/DeleteAiCommentModal.vue";
import DeleteLanguageModal from "../../components/admin/modal/DeleteLanguageModal.vue";
import RTFModal from "../../components/admin/modal/rtfModal.vue";
import EditRTFmodal from "../../components/admin/modal/editRtfModal.vue";
import ReleaseTimeSelectModal from "@/components/admin/modal/ReleaseTimeSelectModal.vue";
import AddCategoryModal from "@/components/admin/modal/AddCategoryModal.vue";
import DeleteCategoryModal from "@/components/admin/modal/DeleteCategoryModal.vue";
import EditCategoryModal from "@/components/admin/modal/editCategoryModal.vue";
import DeleteArticleModal from "@/components/admin/modal/DeleteArticleModal.vue";
import Pagination from "@/components/admin/PaginationComponent.vue";
import {
  tagIdx,
  adminDataTable,
  languageList,
  articleList,
  modalData,
  categories,
  selectLang,
  selectValue,
  articlePage
} from "../../composition/store";
import { useRequestAdminTable } from "@/composition/index";
import {
  editorFn,
  getSingleArticle,
  enableArticle,
  getCategoriesList,
  deleteArticle
} from "@/composition/editor";

export default defineComponent({
  components: {
    AddEcgJudgeModal,
    AddLanguageModal,
    ModifyReportModal,
    HealthCareListModal,
    DeleteAiCommentModal,
    DeleteLanguageModal,
    ModifyCommentNameModal,
    RTFModal,
    ReleaseTimeSelectModal,
    EditRTFmodal,
    AddCategoryModal,
    DeleteCategoryModal,
    EditCategoryModal,
    DeleteArticleModal,
    Pagination
  },
  setup() {
    const {
      requestAdminTable,
      requestLanguageList,
      deleteAiComment
    } = useRequestAdminTable();

    onMounted(async () => {
      await requestAdminTable();
      await requestLanguageList();
      await editorFn(selectLang.value, true, true, 1);
      await getCategoriesList(selectLang.value);
    });

    const myQuillEditor = ref(null);
    const selectTab = (lang, enable, published) => {
      console.log(lang, enable, published);
      selectValue.value.lang = lang;
      selectValue.value.enable = enable;
      selectValue.value.published = published;
    };

    return {
      adminDataTable,
      tagIdx,
      languageList,
      modalData,
      deleteAiComment,
      articleList,
      getSingleArticle,
      enableArticle,
      categories,
      deleteArticle,
      selectLang,
      myQuillEditor,
      selectTab,
      selectValue,
      editorFn,
      articlePage,
      getCategoriesList
    };
  }
});
