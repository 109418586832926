<template>
  <div
    class="modal fade"
    id="addecgjudgemodal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">新增 AI 診斷項目</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default"
              >請輸入新診斷項目名稱</span
            >
            <input
              type="text"
              class="form-control"
              id="formGroupExampleInput"
              v-model="addCommentName"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            取消
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="addAiComment(addCommentName, languageList)"
            data-bs-dismiss="modal"
          >
            確認新增
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRequestAdminTable } from "../../../composition/index";
import { addCommentName, languageList } from "../../../composition/store";

export default {
  setup() {
    const { addAiComment } = useRequestAdminTable();
    return { addAiComment, addCommentName, languageList };
  }
};
</script>
