<template>
  <div
    class="modal fade"
    id="addcategorymodal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">新增文章項目分類</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <label for="articleCategory" class="col-sm-4 col-form-label text-start"
              >語言分類：</label
            >
            <select
              class="form-select form-select-sm w-25 text-start"
              aria-label=".form-select-sm example"
              v-model="newCategory.lang"
            >
              <option selected>select a language</option>
              <option v-for="(item, i) in languageList" :key="i">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default"
              >請輸入文章項目</span
            >
            <input
              type="text"
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              v-model="newCategory.content"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            取消
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="addCategory(newCategory)"
            data-bs-dismiss="modal"
          >
            新增
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { newCategory, languageList } from "../../../composition/store";
import { addCategory } from "@/composition/editor";

export default {
  setup() {
    return { addCategory, newCategory, languageList };
  }
};
</script>
