
import { DatePicker } from "v-calendar";
import { attributes, tagIdx, articleList } from "@/composition/store";
import { releaseArticle } from "@/composition/editor";

export default {
  components: {
    DatePicker
  },
  setup() {
    // const submit = (id, time) => {
    //   console.log(id, time);
    // };
    const date = new Date();
    // date.setMinutes(0, 0, 0);
    return {
      attributes,
      date,
      // submit,
      articleList,
      tagIdx,
      releaseArticle
    };
  }
};
