
import { useRequestAdminTable } from "../../../composition/index";
import { newLang, adminDataTable } from "../../../composition/store";

export default {
  setup() {
    const { addLanguage } = useRequestAdminTable();
    return { addLanguage, newLang, adminDataTable };
  }
};
