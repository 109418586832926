
import {
  tagIdx,
  adminDataTable,
  suggestionDataIdx
} from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";

export default {
  setup() {
    const { deleteSuggestion } = useRequestAdminTable();
    return { tagIdx, adminDataTable, deleteSuggestion, suggestionDataIdx };
  }
};
