
import {
  tagIdx,
  adminDataTable,
  suggestionDataIdx,
  selectLangIdx
} from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";

export default {
  setup() {
    const { editSuggestion } = useRequestAdminTable();
    return {
      tagIdx,
      adminDataTable,
      editSuggestion,
      suggestionDataIdx,
      selectLangIdx
    };
  }
};
