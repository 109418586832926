
import { defineComponent, ref, onMounted } from "vue";
import Modal from "bootstrap/js/dist/modal";
import {
  tempArticleContent,
  // imageList,
  submitBtnDisabled,
  categories,
  languageList
} from "@/composition/store";
import {
  uploadFile,
  submitEditArticle,
  handleSuccess,
  loadArticle,
  // getImageList,
  deleteImage,
  cancelChange,
  getCategoriesList
} from "@/composition/editor";
import { QuillEditor } from "@vueup/vue-quill";
import QuillTableUI from "quill-table-ui";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default defineComponent({
  components: {
    QuillEditor
  },
  setup() {
    const editorOption = {
      modules: [
        {
          name: "blotFormatter",
          module: BlotFormatter
        },
        {
          name: "quillTableUI",
          module: QuillTableUI
        },
        {
          name: "ImageUploader",
          module: ImageUploader,
          options: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                resolve(uploadFile(file));
              });
            }
          }
        }
      ]
    };
    const validateError = ref({
      title: false,
      lang: false,
      category: false,
      cover_image: false
    });
    const editRtfModal = ref(null);
    let thisEditRtfModal;
    onMounted(async () => {
      thisEditRtfModal = new Modal(editRtfModal.value, {});
      // console.log(thisEditRtfModal);
    });

    const hideModal = () => {
      thisEditRtfModal.hide();
      document.querySelector(".modal-backdrop").remove();
    };

    const validate = (editor) => {
      validateError.value.title = false;
      validateError.value.lang = false;
      // validateError.value.category = false;
      validateError.value.cover_image = false;
      if (
        tempArticleContent.value.title !== "" &&
        tempArticleContent.value.lang !== ""
        // tempArticleContent.value.category !== "" &&
        // tempArticleContent.value.cover_image !== ""
      ) {
        // console.log(editor);
        submitEditArticle(editor);
        hideModal();
      } else {
        if (!tempArticleContent.value.title) {
          validateError.value.title = true;
        }
        if (!tempArticleContent.value.lang) {
          validateError.value.lang = true;
        }
        console.log("驗證失敗");

        // if (!tempArticleContent.value.category) {
        //   validateError.value.category = true;
        // }
        // if (!tempArticleContent.value.cover_image) {
        //   validateError.value.cover_image = true;
        // }
      }
    };

    const myQuillEditor = ref(null);

    const setContents: any = (data) => {
      myQuillEditor.value.setContents(data, "silent");
    };
    const fileinput = ref(null);

    return {
      myQuillEditor,
      submitEditArticle,
      editorOption,
      handleSuccess,
      setContents,
      uploadFile,
      fileinput,
      tempArticleContent,
      loadArticle,
      // imageList,
      deleteImage,
      cancelChange,
      submitBtnDisabled,
      categories,
      languageList,
      getCategoriesList,
      validate,
      editRtfModal,
      validateError
    };
  }
});
