
import { tagIdx, adminDataTable, languageList } from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";

export default {
  setup() {
    const { deleteLanguage } = useRequestAdminTable();
    return { tagIdx, adminDataTable, deleteLanguage, languageList };
  }
};
