
import DeleteSuggestionModal from "../modal/DeleteSuggestionModal.vue";
import AddHealthCareModal from "../modal/AddHealthCareModal.vue";
import ModifyHealthCareModal from "../modal/ModifyHealthCareModal.vue";
import { adminDataTable, tagIdx, languageList } from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";
export default {
  components: {
    ModifyHealthCareModal,
    DeleteSuggestionModal,
    AddHealthCareModal
  },
  setup() {
    const {
      editSuggestion,
      deleteSuggestion,
      modalData,
      suggestionData,
      addSuggestion
    } = useRequestAdminTable();
    const removeClassShow = () => {
      document.querySelector("#healthcarelist").classList.remove("show");
      document.querySelector(".modal").classList.add("d-none");
    };
    return {
      removeClassShow,
      adminDataTable,
      tagIdx,
      editSuggestion,
      deleteSuggestion,
      modalData,
      suggestionData,
      languageList,
      addSuggestion
    };
  }
};
