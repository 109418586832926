
import { useRequestAdminTable } from "../../../composition/index";
import {
  newCommentName,
  adminDataTable,
  tagIdx
  // modalData,
} from "../../../composition/store";

export default {
  setup() {
    const { editCommentName } = useRequestAdminTable();
    return { editCommentName, newCommentName, adminDataTable, tagIdx };
  }
};
