<template>
  <div class="container">
    <h1 class="text-center">Admin</h1>
    <div class="d-flex align-items-start">
      <div
        class="nav flex-column nav-pills me-3"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <h2 class="h4">解讀報告後台</h2>
        <button
          class="nav-link active"
          id="v-pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-home"
          type="button"
          role="tab"
          aria-controls="v-pills-home"
          aria-selected="true"
        >
          AI診斷項目
        </button>
        <button
          class="nav-link"
          id="v-pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-profile"
          type="button"
          role="tab"
          aria-controls="v-pills-profile"
          aria-selected="false"
        >
          語系
        </button>
        <button
          class="nav-link"
          id="v-pills-messages-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-messages"
          type="button"
          role="tab"
          aria-controls="v-pills-messages"
          aria-selected="false"
        >
          解讀報告列表
        </button>
        <button
          class="nav-link"
          id="v-pills-settings-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-settings"
          type="button"
          role="tab"
          aria-controls="v-pills-settings"
          aria-selected="false"
        >
          衛教建議列表
        </button>

        <h2 class="h4">衛教資訊後台</h2>
        <button
          class="nav-link"
          id="v-pills-editor-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-editor"
          type="button"
          role="tab"
          aria-controls="v-pills-settings"
          aria-selected="false"
        >
          發布及編輯
        </button>
        <button
          class="nav-link"
          id="v-pills-article-tab"
          data-bs-toggle="pill"
          data-bs-target="#v-pills-editor-category"
          type="button"
          role="tab"
          aria-controls="v-pills-settings"
          aria-selected="false"
        >
          文章項目分類
        </button>
      </div>
      <div class="tab-content w-75 h-auto" id="v-pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="v-pills-home"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <div class="d-flex justify-content-between">
            <h2>AI 診斷項目</h2>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addecgjudgemodal"
            >
              ＋ 新增項目
            </button>
          </div>

          <table class="table">
            <thead>
              <tr>
                <th scope="col">項目</th>
                <th scope="col">編輯</th>
                <th scope="col">刪除</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in adminDataTable" :key="item.comment_id">
                <th scope="row">
                  {{ item.type }}
                </th>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#editcommentnamemodal"
                    @click="modalData(i)"
                  >
                    編輯名稱
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteaicommentmodal"
                    @click="modalData(i)"
                  >
                    刪除
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-profile"
          role="tabpanel"
          aria-labelledby="v-pills-profile-tab"
        >
          <div class="d-flex justify-content-between">
            <h2>語系</h2>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addlanguagemodal"
            >
              ＋ 新增語系
            </button>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">項目</th>
                <th scope="col">刪除</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in languageList" :key="i">
                <th scope="row">{{ item }}</th>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#deletelanguagemodal"
                    @click="modalData(i)"
                  >
                    刪除
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-messages"
          role="tabpanel"
          aria-labelledby="v-pills-messages-tab"
        >
          <div class="d-flex justify-content-between">
            <h2>解讀報告列表</h2>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">項目</th>
                <th scope="col">最後修改時過</th>
                <th scope="col">狀態</th>
                <th scope="col">編輯</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in adminDataTable" :key="item.comment_id">
                <th scope="row">{{ item.type }}</th>
                <td>{{ new Date(item.updated_at).toLocaleString() }}</td>
                <td></td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#modifyreportmodal"
                    @click="modalData(i)"
                  >
                    進入列表
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-settings"
          role="tabpanel"
          aria-labelledby="v-pills-settings-tab"
        >
          <div class="d-flex justify-content-between">
            <h2>衛教建議列表</h2>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">項目</th>
                <th scope="col">最後修改時過</th>
                <th scope="col">狀態</th>
                <th scope="col">編輯</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in adminDataTable" :key="item.comment_id">
                <th scope="row">{{ item.type }}</th>
                <td>{{ new Date(item.updated_at).toLocaleString() }}</td>
                <td></td>
                <td>
                  <a
                    class="btn btn-outline-warning btn-sm"
                    role="button"
                    data-bs-toggle="modal"
                    href="#healthcarelist"
                    @click="modalData(i)"
                  >
                    進入列表
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-editor"
          role="tabpanel"
          aria-labelledby="v-pills-editor-tab"
        >
          <div class="d-flex justify-content-between">
            <h2>衛教資訊 發布及編輯</h2>
            <button
              class="btn btn-outline-warning"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#rtfModal"
            >
              新增草稿
            </button>
          </div>
          <div class="d-flex my-4">
            <label>顯示語言：</label>
            <select
              class="form-select w-25"
              aria-label="Default select example"
              v-model="selectLang"
              @change="editorFn(selectLang, selectValue.enable, selectValue.published, 1)"
            >
              <!-- <option selected value="">Open this select language</option> -->
              <option v-for="(item, i) in languageList" :key="i">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="my-4 d-flex justify-content-between">
            <p class="text-start">文章總數：{{ articleList.counts }}</p>
            <Pagination></Pagination>
            <!-- <form action="" class="d-flex">
              <button class="btn btn-secondary me-2 btn-sm">Previous</button>
              <input
                class="form-control me-2"
                type="search"
                placeholder="請填入欲前往之頁數"
                aria-label="Search"
              />
              <button class="btn btn-secondary btn-sm">Next</button>
            </form> -->
          </div>

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                @click="editorFn(selectLang, true, true, 1)"
              >
                已發布
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                @click="editorFn(selectLang, true, false, 1)"
              >
                未發布草稿
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#revoke"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                @click="editorFn(selectLang, false, true, 1)"
              >
                已撤銷
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">序號</th>
                    <th scope="col">標題</th>
                    <th scope="col">文章分類</th>
                    <th scope="col">發布時間</th>
                    <th scope="col">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in articleList.posts" :key="item._id">
                    <!-- <template
                      v-if="
                        item.published_flag === true &&
                        item.enabled === true &&
                        item.lang === selectLang
                      "
                    > -->
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ item.title }}</td>
                    <td>{{ item.category.name }}</td>
                    <td>{{ item.published_time }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-secondary"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editRtfModal"
                        @click="getSingleArticle(item._id)"
                      >
                        編輯</button
                      ><button
                        class="btn btn-sm btn-danger"
                        @click="enableArticle(item._id, false)"
                      >
                        取消發布
                      </button>
                    </td>
                    <!-- </template> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">序號</th>
                    <th scope="col">標題</th>
                    <th scope="col">文章分類</th>

                    <th scope="col">操作</th>
                    <th scope="col">發布</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in articleList.posts" :key="item._id">
                    <!-- <template
                      v-if="
                        item.published_flag === false &&
                        item.enabled === true &&
                        item.lang === selectLang
                      "
                    > -->
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ item.title }}</td>
                    <td>{{ item.category.name }}</td>

                    <td>
                      <button
                        class="btn btn-sm btn-secondary"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editRtfModal"
                        @click="getSingleArticle(item._id)"
                      >
                        編輯</button
                      ><button
                        class="btn btn-sm btn-danger"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#deletearticlemodal"
                        @click="modalData(index)"
                      >
                        刪除
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#releaseTimeSelectModal"
                        @click="modalData(index)"
                      >
                        發布
                      </button>
                    </td>
                    <!-- </template> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane fade"
              id="revoke"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">序號</th>
                    <th scope="col">標題</th>
                    <th scope="col">文章分類</th>

                    <th scope="col">操作</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in articleList.posts" :key="item._id">
                    <!-- <template v-if="item.enabled === false && item.lang === selectLang"> -->
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ item.title }}</td>
                    <td>{{ item.category.name }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-secondary"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editRtfModal"
                        @click="getSingleArticle(item._id)"
                      >
                        編輯</button
                      ><button
                        class="btn btn-sm btn-danger"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#deletearticlemodal"
                        @click="modalData(index)"
                      >
                        刪除
                      </button>
                    </td>
                    <td>
                      <button
                        class="btn btn-sm btn-primary"
                        @click="enableArticle(item._id, true)"
                      >
                        再上架
                      </button>
                    </td>
                    <!-- </template> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-editor-category"
          role="tabpanel"
          aria-labelledby="v-pills-editor-category-tab"
        >
          <div class="d-flex justify-content-between">
            <h2>衛教資訊 文章項目分類</h2>
            <button
              class="btn btn-outline-warning"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addcategorymodal"
            >
              新增文章項目
            </button>
          </div>
          <div class="d-flex my-4">
            <label>顯示語言：</label>
            <select
              class="form-select w-25"
              aria-label="Default select example"
              v-model="selectLang"
              @change="getCategoriesList(selectLang)"
            >
              <!-- <option selected value="">Open this select language</option> -->
              <option v-for="(item, i) in languageList" :key="i">
                {{ item }}
              </option>
            </select>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">項目</th>
                <th scope="col">編輯</th>
                <th scope="col">刪除</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in categories" :key="i">
                <th scope="row">{{ item.name }}</th>
                <th>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#editcategorymodal"
                    @click="modalData(i)"
                  >
                    編輯
                  </button>
                </th>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#deletecategorymodal"
                    @click="modalData(i)"
                  >
                    刪除
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <AddEcgJudgeModal></AddEcgJudgeModal>
  <AddLanguageModal></AddLanguageModal>
  <ModifyReportModal></ModifyReportModal>
  <HealthCareListModal></HealthCareListModal>
  <DeleteAiCommentModal></DeleteAiCommentModal>
  <DeleteLanguageModal></DeleteLanguageModal>
  <ModifyCommentNameModal></ModifyCommentNameModal>
  <RTFModal></RTFModal>
  <EditRTFmodal></EditRTFmodal>
  <ReleaseTimeSelectModal></ReleaseTimeSelectModal>
  <AddCategoryModal></AddCategoryModal>
  <DeleteCategoryModal></DeleteCategoryModal>
  <DeleteArticleModal></DeleteArticleModal>
  <EditCategoryModal></EditCategoryModal>
</template>

<script lang="ts">
import { onMounted, defineComponent, ref } from "vue";
import AddEcgJudgeModal from "../../components/admin/modal/AddEcgJudgeModal.vue";
import AddLanguageModal from "../../components/admin/modal/AddLanguageModal.vue";
import ModifyCommentNameModal from "../../components/admin/modal/ModifyCommentNameModal.vue";
import ModifyReportModal from "../../components/admin/modal/ModifyReportModal.vue";
import HealthCareListModal from "../../components/admin/modal/HealthCareListModal.vue";
import DeleteAiCommentModal from "../../components/admin/modal/DeleteAiCommentModal.vue";
import DeleteLanguageModal from "../../components/admin/modal/DeleteLanguageModal.vue";
import RTFModal from "../../components/admin/modal/rtfModal.vue";
import EditRTFmodal from "../../components/admin/modal/editRtfModal.vue";
import ReleaseTimeSelectModal from "@/components/admin/modal/ReleaseTimeSelectModal.vue";
import AddCategoryModal from "@/components/admin/modal/AddCategoryModal.vue";
import DeleteCategoryModal from "@/components/admin/modal/DeleteCategoryModal.vue";
import EditCategoryModal from "@/components/admin/modal/editCategoryModal.vue";
import DeleteArticleModal from "@/components/admin/modal/DeleteArticleModal.vue";
import Pagination from "@/components/admin/PaginationComponent.vue";
import {
  tagIdx,
  adminDataTable,
  languageList,
  articleList,
  modalData,
  categories,
  selectLang,
  selectValue,
  articlePage
} from "../../composition/store";
import { useRequestAdminTable } from "@/composition/index";
import {
  editorFn,
  getSingleArticle,
  enableArticle,
  getCategoriesList,
  deleteArticle
} from "@/composition/editor";

export default defineComponent({
  components: {
    AddEcgJudgeModal,
    AddLanguageModal,
    ModifyReportModal,
    HealthCareListModal,
    DeleteAiCommentModal,
    DeleteLanguageModal,
    ModifyCommentNameModal,
    RTFModal,
    ReleaseTimeSelectModal,
    EditRTFmodal,
    AddCategoryModal,
    DeleteCategoryModal,
    EditCategoryModal,
    DeleteArticleModal,
    Pagination
  },
  setup() {
    const {
      requestAdminTable,
      requestLanguageList,
      deleteAiComment
    } = useRequestAdminTable();

    onMounted(async () => {
      await requestAdminTable();
      await requestLanguageList();
      await editorFn(selectLang.value, true, true, 1);
      await getCategoriesList(selectLang.value);
    });

    const myQuillEditor = ref(null);
    const selectTab = (lang, enable, published) => {
      console.log(lang, enable, published);
      selectValue.value.lang = lang;
      selectValue.value.enable = enable;
      selectValue.value.published = published;
    };

    return {
      adminDataTable,
      tagIdx,
      languageList,
      modalData,
      deleteAiComment,
      articleList,
      getSingleArticle,
      enableArticle,
      categories,
      deleteArticle,
      selectLang,
      myQuillEditor,
      selectTab,
      selectValue,
      editorFn,
      articlePage,
      getCategoriesList
    };
  }
});
</script>
