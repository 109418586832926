
import {
  tagIdx,
  adminDataTable,
  languageList,
  newSuggestion
} from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";

export default {
  setup() {
    const { addSuggestion } = useRequestAdminTable();
    // const print = () => {
    //   console.log(newSuggestion.value);
    // };
    return {
      tagIdx,
      adminDataTable,
      languageList,
      newSuggestion,
      addSuggestion
    };
  }
};
