<template>
  <div
    class="modal fade"
    id="deletecategorymodal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            確定要刪除{{ selectLang }}語言下的分類『{{ categories[tagIdx].name }}』嗎 ？
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            取消
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="deleteCategory(selectLang, categories[tagIdx].category_id)"
          >
            確認刪除
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { tagIdx, categories, selectLang } from "../../../composition/store";
import { deleteCategory } from "@/composition/editor";

export default {
  setup() {
    return { tagIdx, categories, selectLang, deleteCategory };
  }
};
</script>
