<template>
  <div
    class="modal fade"
    id="releaseTimeSelectModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">設定發布日期</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <DatePicker
            mode="dateTime"
            :minute-increment="5"
            is-expanded
            v-model="date"
          ></DatePicker>
        </div>
        <div class="modal-footer">
          <!-- <span>{{ articleList[tagIdx] }}</span> -->
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="releaseArticle(articleList.posts[tagIdx]._id, date)"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DatePicker } from "v-calendar";
import { attributes, tagIdx, articleList } from "@/composition/store";
import { releaseArticle } from "@/composition/editor";

export default {
  components: {
    DatePicker
  },
  setup() {
    // const submit = (id, time) => {
    //   console.log(id, time);
    // };
    const date = new Date();
    // date.setMinutes(0, 0, 0);
    return {
      attributes,
      date,
      // submit,
      articleList,
      tagIdx,
      releaseArticle
    };
  }
};
</script>
