
import { useRequestAdminTable } from "../../../composition/index";
import { addCommentName, languageList } from "../../../composition/store";

export default {
  setup() {
    const { addAiComment } = useRequestAdminTable();
    return { addAiComment, addCommentName, languageList };
  }
};
