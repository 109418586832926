<template>
  <div
    class="modal fade"
    id="editcommentnamemodal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">修改 Comment 名稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default"
              >請輸入新名稱</span
            >
            <input
              type="text"
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              v-model="newCommentName"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            取消
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="editCommentName(newCommentName, adminDataTable[tagIdx].comment_id)"
            data-bs-dismiss="modal"
          >
            新增
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { useRequestAdminTable } from "../../../composition/index";
import {
  newCommentName,
  adminDataTable,
  tagIdx
  // modalData,
} from "../../../composition/store";

export default {
  setup() {
    const { editCommentName } = useRequestAdminTable();
    return { editCommentName, newCommentName, adminDataTable, tagIdx };
  }
};
</script>
