<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="editRtfModal"
    ref="editRtfModal"
    tabindex="-1"
    aria-labelledby="editrtfModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="rtfModalLabel">編輯文章</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cancelChange(myQuillEditor)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-8">
              <div class="mb-3 row">
                <label for="articleTitle" class="col-sm-4 col-form-label text-start"
                  >標題：</label
                >
                <input
                  type="text"
                  id="articleTitle"
                  class="form-control"
                  :class="{ 'is-invalid': validateError.title }"
                  v-model="tempArticleContent.title"
                />
                <div
                  class="invalid-feedback"
                  id="feedback-1"
                  v-if="!tempArticleContent.title"
                >
                  標題為必填欄位
                </div>
              </div>
              <div class="row mb-3">
                <label for="articleCategory" class="col-sm-4 col-form-label text-start"
                  >語言分類：</label
                >
                <select
                  class="form-select form-select-sm"
                  :class="{ 'is-invalid': validateError.lang }"
                  aria-label=".form-select-sm example"
                  v-model="tempArticleContent.lang"
                  @change="getCategoriesList(tempArticleContent.lang)"
                >
                  <option selected>select a language</option>
                  <option v-for="(item, i) in languageList" :key="i">{{ item }}</option>
                </select>
                <div
                  class="invalid-feedback"
                  id="feedback-2"
                  v-if="!tempArticleContent.lang"
                >
                  語言為必選欄位
                </div>
              </div>
              <div class="row mb-3">
                <label for="articleCategory" class="col-sm-4 col-form-label text-start"
                  >文章分類：</label
                >
                <select
                  class="form-select form-select-sm"
                  :class="{ 'is-invalid': validateError.category }"
                  aria-label=".form-select-sm example"
                  v-model="tempArticleContent.category"
                >
                  <option v-for="item in categories" :value="item.category_id">
                    {{ item.name }}
                  </option>
                </select>
                <div
                  class="invalid-feedback"
                  id="feedback-3"
                  v-if="!tempArticleContent.category"
                >
                  分類為必選欄位
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="input-group mb-3">
                    <label
                      for="article_covr_image"
                      class="col-sm-4 col-form-label text-start"
                      >縮圖：</label
                    >
                    <input
                      type="file"
                      class="form-control"
                      :class="{ 'is-invalid': validateError.cover_image }"
                      id="article_covr_image"
                      aria-describedby="inputGroupFileAddon03"
                      aria-label="Upload"
                      ref="fileinput"
                      @change="handleSuccess(fileinput.files[0])"
                    />
                    <div
                      class="invalid-feedback"
                      id="feedback-4"
                      v-if="!tempArticleContent.cover_image_link"
                    >
                      縮圖為必上傳欄位
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="loadArticle(myQuillEditor, tempArticleContent.content)"
                >
                  載入文章
                </button>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center" v-if="tempArticleContent.cover_image_link">
                <img
                  :src="tempArticleContent.cover_image_link"
                  class="rounded img-fluid"
                  alt="文章縮圖"
                />
                <button class="btn btn-sm btn-danger" @click="deleteImage()">
                  刪除縮圖
                </button>
              </div>
            </div>
          </div>

          <QuillEditor
            ref="myQuillEditor"
            theme="snow"
            toolbar="full"
            contentType="text"
            v-model:content="tempArticleContent.content"
            :modules="editorOption.modules"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="cancelChange(myQuillEditor)"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="validate(myQuillEditor)">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import Modal from "bootstrap/js/dist/modal";
import {
  tempArticleContent,
  // imageList,
  submitBtnDisabled,
  categories,
  languageList
} from "@/composition/store";
import {
  uploadFile,
  submitEditArticle,
  handleSuccess,
  loadArticle,
  // getImageList,
  deleteImage,
  cancelChange,
  getCategoriesList
} from "@/composition/editor";
import { QuillEditor } from "@vueup/vue-quill";
import QuillTableUI from "quill-table-ui";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default defineComponent({
  components: {
    QuillEditor
  },
  setup() {
    const editorOption = {
      modules: [
        {
          name: "blotFormatter",
          module: BlotFormatter
        },
        {
          name: "quillTableUI",
          module: QuillTableUI
        },
        {
          name: "ImageUploader",
          module: ImageUploader,
          options: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                resolve(uploadFile(file));
              });
            }
          }
        }
      ]
    };
    const validateError = ref({
      title: false,
      lang: false,
      category: false,
      cover_image: false
    });
    const editRtfModal = ref(null);
    let thisEditRtfModal;
    onMounted(async () => {
      thisEditRtfModal = new Modal(editRtfModal.value, {});
      // console.log(thisEditRtfModal);
    });

    const hideModal = () => {
      thisEditRtfModal.hide();
      document.querySelector(".modal-backdrop").remove();
    };

    const validate = (editor) => {
      validateError.value.title = false;
      validateError.value.lang = false;
      // validateError.value.category = false;
      validateError.value.cover_image = false;
      if (
        tempArticleContent.value.title !== "" &&
        tempArticleContent.value.lang !== ""
        // tempArticleContent.value.category !== "" &&
        // tempArticleContent.value.cover_image !== ""
      ) {
        // console.log(editor);
        submitEditArticle(editor);
        hideModal();
      } else {
        if (!tempArticleContent.value.title) {
          validateError.value.title = true;
        }
        if (!tempArticleContent.value.lang) {
          validateError.value.lang = true;
        }
        console.log("驗證失敗");

        // if (!tempArticleContent.value.category) {
        //   validateError.value.category = true;
        // }
        // if (!tempArticleContent.value.cover_image) {
        //   validateError.value.cover_image = true;
        // }
      }
    };

    const myQuillEditor = ref(null);

    const setContents: any = (data) => {
      myQuillEditor.value.setContents(data, "silent");
    };
    const fileinput = ref(null);

    return {
      myQuillEditor,
      submitEditArticle,
      editorOption,
      handleSuccess,
      setContents,
      uploadFile,
      fileinput,
      tempArticleContent,
      loadArticle,
      // imageList,
      deleteImage,
      cancelChange,
      submitBtnDisabled,
      categories,
      languageList,
      getCategoriesList,
      validate,
      editRtfModal,
      validateError
    };
  }
});
</script>
