
import { computed, ref } from "vue";
import { editorFn } from "@/composition/editor";
import { articleList, selectLang, selectValue, articlePage } from "@/composition/store";
export default {
  setup() {
    // const { requestDiagnoses } = useRequestDiagnoses();
    // const page = ref(1);
    const pageNumber = computed(() => {
      return Math.ceil(articleList.value.counts / 10);
    });
    // const pageParameter = ref(0);
    const next = () => {
      if (
        pageNumber.value > articlePage.value &&
        articlePage.value !== pageNumber.value
      ) {
        articlePage.value++;
        editorFn(
          selectLang.value,
          selectValue.value.enable,
          selectValue.value.published,
          articlePage.value
        );
      } else {
        return;
      }
    };
    const previous = () => {
      if (pageNumber.value >= articlePage.value && articlePage.value !== 1) {
        articlePage.value--;
        editorFn(
          selectLang.value,
          selectValue.value.enable,
          selectValue.value.published,
          articlePage.value
        );
        return;
      } else if (articlePage.value === 1) {
        return;
      }
    };
    return {
      articleList,
      pageNumber,
      editorFn,
      next,
      previous,
      articlePage,
      selectValue,
      selectLang
    };
  }
};
