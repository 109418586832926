<template>
  <div
    class="modal fade"
    id="healthcarelist"
    aria-hidden="true"
    aria-labelledby="healthcarelistLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row w-100 justify-content-between">
            <div class="col-4">
              <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
                <h5 class="modal-title">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">衛教建議列表</li>
                    <li
                      class="breadcrumb-item active"
                      aria-current="page"
                      id="modal-title"
                    >
                      {{ adminDataTable[tagIdx].type }}
                    </li>
                  </ol>
                </h5>
              </nav>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">選擇語言</th>
                <th scope="col">內容</th>
                <th scope="col">狀態</th>
                <th scope="col">刪除</th>
                <th scope="col">編輯</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in adminDataTable[tagIdx].suggestions"
                :key="item.id"
              >
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  <select
                    class="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                    v-model="item.default_idx"
                  >
                    <option selected disabled>選擇語言</option>
                    <option
                      :value="j"
                      v-for="(item2, j) in item.text_contents"
                      :key="item2.lang"
                    >
                      {{ item2.lang }}
                    </option>
                  </select>
                </td>
                <td>
                  {{ item.text_contents[item.default_idx].text_content }}
                </td>
                <td></td>
                <td>
                  <button
                    class="btn btn-info"
                    data-bs-target="#deletesuggestionmodal"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    @click="suggestionData(i)"
                  >
                    刪除
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-info"
                    @click="suggestionData(i)"
                    data-bs-target="#modifyhealthcaremodal"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    編輯
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            @click="addSuggestion(adminDataTable[tagIdx], languageList)"
          >
            + 新增
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModifyHealthCareModal></ModifyHealthCareModal>
  <DeleteSuggestionModal></DeleteSuggestionModal>
  <AddHealthCareModal></AddHealthCareModal>
</template>
<script lang="ts">
import DeleteSuggestionModal from "../modal/DeleteSuggestionModal.vue";
import AddHealthCareModal from "../modal/AddHealthCareModal.vue";
import ModifyHealthCareModal from "../modal/ModifyHealthCareModal.vue";
import { adminDataTable, tagIdx, languageList } from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";
export default {
  components: {
    ModifyHealthCareModal,
    DeleteSuggestionModal,
    AddHealthCareModal
  },
  setup() {
    const {
      editSuggestion,
      deleteSuggestion,
      modalData,
      suggestionData,
      addSuggestion
    } = useRequestAdminTable();
    const removeClassShow = () => {
      document.querySelector("#healthcarelist").classList.remove("show");
      document.querySelector(".modal").classList.add("d-none");
    };
    return {
      removeClassShow,
      adminDataTable,
      tagIdx,
      editSuggestion,
      deleteSuggestion,
      modalData,
      suggestionData,
      languageList,
      addSuggestion
    };
  }
};
</script>
