<template>
  <div
    class="modal fade"
    id="addhealthcaremodal"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            編輯項目：{{ adminDataTable[tagIdx].type }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h6>衛教建議：</h6>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label"></label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="newSuggestion.lang"
            >
              <option selected>選擇語系</option>
              <option v-for="(item, i) in languageList" :key="i" :value="item">
                {{ item }}
              </option>
            </select>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="newSuggestion.suggestions"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            data-bs-target="#exampleModalToggle"
            data-bs-toggle="modal"
          >
            Back to first
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="addSuggestion(adminDataTable[tagIdx], newSuggestion)"
          >
            新增
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  tagIdx,
  adminDataTable,
  languageList,
  newSuggestion
} from "../../../composition/store";
import { useRequestAdminTable } from "../../../composition/index";

export default {
  setup() {
    const { addSuggestion } = useRequestAdminTable();
    // const print = () => {
    //   console.log(newSuggestion.value);
    // };
    return {
      tagIdx,
      adminDataTable,
      languageList,
      newSuggestion,
      addSuggestion
    };
  }
};
</script>
